import CardsSection from "../components/sections/CardsSection/CardsSection"
import CtaSection from "../components/sections/CtaSection"
import FaqAccordionSection from "../components/sections/FaqAccordionSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import React from "react"
import { SectionHeadline } from "../reusable.styles"
import SectionWrapper from "../components/sections/SectionWrapper"
import { StaticImage } from "gatsby-plugin-image";
import ValuePropsSection from "../components/sections/ValuePropsSection"
import { queries } from "../breakpoints"
import styled from 'styled-components';

const BackToWork = ({ location }) => {

  const valueProps = [
    {
      headline:"Scheduled Team Order",
      subheadline:"Administrators pick a restaurant and employees order together. Each order is labeled and bagged individually then delivered together.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933620/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/J-BackToWorkSafety/TopSection/EXPORTS-Illustrations-BackToWorkSafety-Top4-GroupOrder-Citrus.png",
    },
    {
      headline:"Group Order",
      subheadline:"Anyone can pick a restaurant and employees order together. Then all orders are delivered together.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933620/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/J-BackToWorkSafety/TopSection/EXPORTS-Illustrations-BackToWorkSafety-Top4-OpenGroupOrder-Citrus.png",
    },
    {
      headline:"Individual Meal Perks",
      subheadline:"Provide a meal perk for employees to order for themselves, setting budgets and rules to control spend.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1625001193/Grubhub%20Corporate%20Website/2021/Illustrations/J-BackToWorkSafety/TopSection/EXPORTS-Illustrations-BackToWorkSafety-Top4-IndividualMels.png",
    },
    {
      headline:"Individually boxed catering",
      subheadline:"Use our new search filter to find restaurants that will individually box meals for your employees.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1625001192/Grubhub%20Corporate%20Website/2021/Illustrations/J-BackToWorkSafety/TopSection/EXPORTS-Illustrations-BackToWorkSafety-Top4-IndividuallyBoxedCatering.png",
    },
  ]

  const cards = [
    {
      title: "Digital Events",
      description: "Make your digital lunch and learns, webinars and client meetings more delicious with food!",
      link: "/meals-for-digital-events/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-DigitalEvents.jpg",
      linkText: "Learn more"
    },
    {
      title: "Corporate Perks",
      description: "Stretch your budget further with Grubhub Perks. Offer employees free food, deals and more.",
      link: "/perks/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797392/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-CorporatePerks.jpg",
      linkText: "Learn more"
    },
    {
      title: "Remote Employees",
      description: "Show your remote team you care with customizable meal plans from Grubhub.",
      link: "/meals-for-remote-employees/",
      imageSrc: "https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797391/Grubhub%20Corporate%20Website/2021/Photography%20Elements/_All%20Small%20Card%20Header%20Images/EXPORTS-CARD1-Images-RemoteEmployees.jpg",
      linkText: "Learn more"
    },
    {
      title: "Hybrid Teams",
      description: "Support hybrid teams at home and at the office with meals they’ll love.",
      link:"/hybrid-teams/",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1661441639/Grubhub%20Corporate%20Website/Hybrid%20Teams%20Page/HybridTeams.jpg",
      linkText: "Learn more"
    }
  ]

  const faqs = [
    {
      header: "How do you entice employees back to the office?",
      description: "You should ensure employees feel safe returning to the office. Make sure you communicate your plans, including your office meal plan, to ensure everyone feels comfortable."
    },
    {
      header: "What are some back to office lunch ideas? ",
      description: "Try hosting socially distant team lunches back in the office with individually packaged food like sandwiches and salads. This could be a casual lunch, a lunch and learn, or you could include activities like team trivia."
    }
  ]

  return (
    <Layout
      location={location}
      title={"Back to Work | Grubhub Corporate"}
      description={"We are prepared to make your move back to work easy, safe and delicious. Here's how your team can order safely."}
      className="back-to-work-page"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "ordering occasions",
        pageName: "back to work safety",
        brand: "grubhub"
      }}
      faqSeoList={faqs}
    >
      <GeneralHeader
        headline="Safe ordering"
        subheadline="We make office meals easy, safe and delicious with options like Curbside Pickup and Contact-Free Delivery."
        textColor="var(--color-secondary)"
        background="rgba(186,228,238,0.2)"
        ctaLink="/get-started/"
        ctaText="Get started"
        type="half-image"
        alt="Grubhub bag sitting on doorstep next to two pizza boxes with two coffee cups and a tea on top of them."
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933628/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Header%20Images/Subpage%20Headers/HEADERS-SUBPAGES-2560x1707-BackToWorkSafety-Citrus.jpg"
      />
      <ValuePropsSection
        headline="How your team can order safely"
        valueProps={valueProps}
        layout="horizontal"
        propsPerRow={2}
      />
      <QuoteSection
        quote='"Having a corporate account has made it much easier to make sure all employees are taken care of. They have the option to purchase personal meals or group orders and I always have someone to go to if I have questions or concerns."'
        author="Giana R"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627927171/Grubhub%20Corporate%20Website/2021/Partner%20Logos/PARTNERLOGOS-GoFundMe.png"
        alt="GoFundMe logo"
        position="Workplace and Employee Experience Manager at GoFundMe"
        backgroundColor="rgba(186,228,238,0.1)"
      />
      <SectionWrapper className="back-to-work-page__grid-section-wrapper">
        <SectionHeadline>What we've done to make ordering safer</SectionHeadline>
        <CardGrid/>
      </SectionWrapper>
      <CardsSection backgroundColor="rgba(186,228,238,0.1)" headline="More ordering occasions and information" cards={cards} cardsPerRow={3} chunkCards isSliderOnMobile/>
      <CtaSection headline="See what we can do for you" backgroundColor="white"/>
      <FaqAccordionSection faqs={faqs} backgroundColor="#F9F9F9"/>
    </Layout>
  )
}

export default BackToWork


const CardGrid = () => {
  return(
    <GridSection>
      <Block>
        <ImageContainer>
          <StaticImage placeholder="blurred" layout="constrained" src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933620/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/J-BackToWorkSafety/BottomSection/EXPORTS-Illustrations-BackToWorkSafety-Bottom4-Icon1-Citrus.png" alt="For restaurants"/>
        </ImageContainer>
        <BlockHeadline>For restaurants</BlockHeadline>
        <BlockBody>Provided restaurant partners with enhanced business tools to ensure their operations run smoothly and safely with increased delivery orders. We continue to share up-to-date safety resources in our Learning Center based on CDC and National Restaurant Association guidelines.</BlockBody>
      </Block>
      <Block>
        <ImageContainer>
          <StaticImage placeholder="blurred" layout="constrained" src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933620/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/J-BackToWorkSafety/BottomSection/EXPORTS-Illustrations-BackToWorkSafety-Bottom4-Icon2-Citrus.png" alt="For Drivers" />
        </ImageContainer>
        <BlockHeadline>For Drivers</BlockHeadline>
        <BlockBody>Gave access to free personal protective equipment. So they have everything they need to deliver safely.</BlockBody>
      </Block>
      <Block>
        <ImageContainer>
          <StaticImage placeholder="blurred" layout="constrained" src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933620/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Illustrations/J-BackToWorkSafety/BottomSection/EXPORTS-Illustrations-BackToWorkSafety-Bottom4-Icon3-Citrus.png" alt="For You" />
        </ImageContainer>
        <BlockHeadline>For You</BlockHeadline>
        <BlockBody>Created two new safety features, <strong>Contact-free delivery</strong> and <strong>Curbside pickup</strong>, for you to consider at checkout.</BlockBody>
      </Block>
      <Block>
        <ImageContainer>
          <StaticImage placeholder="blurred" layout="constrained" src="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1625001190/Grubhub%20Corporate%20Website/2021/Illustrations/J-BackToWorkSafety/BottomSection/EXPORTS-Illustrations-BackToWorkSafety-Bottom4-Icon4.png" alt="For our Communities" />
        </ImageContainer>
        <BlockHeadline>For our Communities</BlockHeadline>
        <BlockBody>Started the <a href="https://blog.grubhub.com/covid-19" target="_blank" rel="noreferrer">Grubhub Community Relief Fund</a> to garner support for restaurants–the cornerstones in our communities. Customers can donate their change at checkout with the proceeds going to organizations that support those impacted by COVID-19, including restaurants, delivery partners and those in need.</BlockBody>
      </Block>
    </GridSection>
  )
}

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 2rem;
  @media(${queries.max_break_md}){
    display:flex;
    flex-flow:column;

  }
`;

const ImageContainer = styled.div`
  max-width:150px;
`;

const Block = styled.div`
  background-color: purple;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid rgba(199, 184, 255, 0.42);
  border-radius: 10px;
  background-color: #F9F9F9;
  box-shadow: 0 2px 4px 0 rgba(67, 41, 163, 0.1);
  padding: 20px 40px;
`;

const BlockHeadline = styled.h3`
  color: #23232E;
  font-size: var(--fontSize-3);
  font-weight: bold;
  margin: 15px 0;
`;

const BlockBody = styled.p`
  font-size: var(--fontSize-2);
  text-align: center;
`;
